import { Cookies } from 'quasar';

const tokenKey = 'TOKEN';
const previousUserMailKey = 'PREVIOUS_USER_MAIL';
const foodOfficeApiKey = 'FOODOFFFICE_API';
const logoUrlKey = 'LOGO_URL';
const languageKey = 'LANGUAGE';
const expirationDate = new Date().getDate() + 30;

function setToken(token: string) {
  Cookies.set(tokenKey, token, {
    expires: expirationDate,
  });
}

function getToken(): string {
  return Cookies.get(tokenKey);
}

function removeToken() {
  Cookies.remove(tokenKey);
}

function setPreviousUserMail(userMail: string) {
  Cookies.set(previousUserMailKey, userMail, {
    expires: expirationDate,
  });
}

function getPreviousUserMail(): string | false {
  const previousUserMail = Cookies.get(previousUserMailKey);
  if (previousUserMail) {
    return previousUserMail;
  }
  return false;
}

function setLogoUrl(logoUrl: string) {
  Cookies.set(logoUrlKey, logoUrl, {
    expires: expirationDate,
  });
}

function getLogoUrl(): string | boolean {
  const logoUrl = Cookies.get(logoUrlKey) as string;
  if (logoUrl) {
    return logoUrl;
  }
  return false;
}

function setFoodOfficeApiUrl(foodOfficeApiUrl: string) {
  Cookies.set(foodOfficeApiKey, foodOfficeApiUrl, {
    expires: expirationDate,
  });
}

function getFoodOfficeApiUrl(): string | boolean {
  const foodOfficeApiUrl = Cookies.get(foodOfficeApiKey) as string;
  if (foodOfficeApiUrl) {
    return foodOfficeApiUrl;
  }
  return false;
}

function setLanguage(language: string) {
  Cookies.set(languageKey, language, {
    expires: expirationDate,
  });
}

function getLanguage(): string {
  const currentLanguage = Cookies.get(languageKey) as string;
  if (currentLanguage) {
    return currentLanguage;
  }
  setLanguage('de-DE');
  return 'de-DE';
}

function setCustomerCode(customerCode: string) {
  Cookies.set('customerCode', customerCode, {
    expires: expirationDate,
  });
}

function getCustomerCode(): string {
  const customerCode = Cookies.get('customerCode') as string;
  if (customerCode) {
    return customerCode;
  }
  return '';
}

function setAccountId(accountId: string) {
  Cookies.set('accountId', accountId, {
    expires: expirationDate,
  });
}

function getAccountId(): string {
  const accountId = Cookies.get('accountId') as string;
  if (accountId) {
    return accountId;
  }
  return '';
}

function setPreviousAppVersion(version: string) {
  Cookies.set('previousAppVersion', version, {
    expires: expirationDate,
  });
}

function getPreviousAppVersion(): string {
  const previousAppVersion = Cookies.get('previousAppVersion') as string;
  if (previousAppVersion) {
    return previousAppVersion;
  }
  return '';
}

export {
  setToken,
  getToken,
  removeToken,
  setPreviousUserMail,
  getPreviousUserMail,
  setLogoUrl,
  getLogoUrl,
  setFoodOfficeApiUrl,
  getFoodOfficeApiUrl,
  setLanguage,
  getLanguage,
  setCustomerCode,
  getCustomerCode,
  setAccountId,
  getAccountId,
  setPreviousAppVersion,
  getPreviousAppVersion,
};

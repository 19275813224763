import jwtDecode from 'jwt-decode';
import { Token } from 'src/interfaces/Infrastructure/Token';
import { getToken } from 'src/services/CookiesService';

function isTokenValid(): boolean {
  const token = getToken();
  if (token) {
    const decodeToken: Token = jwtDecode(token);
    const { exp } = decodeToken;
    const currentDate = Math.floor(new Date().getTime() / 1000);
    return exp > currentDate;
  }
  return false;
}

function getUserMail(): string {
  const token = getToken();
  const decodeToken: Token = jwtDecode(token);
  const { sub } = decodeToken;
  return sub;
}

function getUserAccount(): string {
  const token = getToken();
  const decodeToken: Token = jwtDecode(token);
  const { foAccountId } = decodeToken;
  return foAccountId;
}
function getUserCustomerCode(): string {
  const token = getToken();
  const decodeToken: Token = jwtDecode(token);
  const { customerCode } = decodeToken;
  return customerCode;
}

export { isTokenValid, getUserMail, getUserAccount, getUserCustomerCode };

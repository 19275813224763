import { defineStore } from 'pinia';

interface BeforeInstallPromptEvent extends Event {
  prompt: () => Promise<void>;
  userChoice: Promise<{ outcome: 'accepted' | 'dismissed', platform: string }>;
}

export const usePwaInstallEventStore = defineStore('PwaInstallEvent', {
  state: () => ({
    event: null as BeforeInstallPromptEvent | null,
    isInstallable: true
  })
});

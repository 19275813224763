import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'IndexPage',
        component: () => import('src/pages/IndexPage.vue'),
      },
      {
        path: '/orderhistory',
        name: 'OrderHistoryPage',
        component: () => import('src/pages/OrderHistoryPage.vue'),
      },
      {
        path: '/orderdetail/:id',
        name: 'OrderDetailPage',
        component: () => import('src/pages/OrderDetailPage.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('pages/LoginPage.vue'),
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import('pages/SettingsPage.vue'),
      },
      {
        path: '/reset-password-initialize/',
        name: 'ResetPasswordInitialize',
        component: () => import('src/pages/ResetPasswordInitializePage.vue'),
      },
      {
        path: '/reset-password/',
        name: 'ResetPasswordFinalize',
        component: () => import('src/pages/ResetPasswordFinalizePage.vue'),
      },
    ],
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '/:catchAll(.*)*',
    component: () => import('pages/ErrorNotFound.vue'),
  },
];

export default routes;
